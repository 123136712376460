body{
    margin: 0;
    background-image: url('./assets/images/Desktop.png');
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}
button , a{
    cursor: pointer;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
  }

  .upload-btn{
    font-family: "Gosha Sans Regular";
    background-color: #1F1F1F;
    border: none;
    color: white;
    text-transform: uppercase;
    border-radius: 40px;
    width: 125px;
    height: 62px;
    font-size: 16px;
    &:hover{
      background-color:white;
      color:black;
    }
}
.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid black;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 500px) {
 body{
  background-image: url('./assets/images/Mobile.png');
 }
}